/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable react/jsx-curly-newline */
import { type ReactElement, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import { agentLogsState, alarmHistoryState } from 'src/UIState'
import { adminRoles } from 'src/utils/role'
import Container from 'src/Layout/Page'
import Page from './Page'

const UserProfile = lazy(() => import('src/UserProfile'))
const GeoZoneMap = lazy(() => import('src/Maps/GeoZoneMap'))
const Dashboard = lazy(() => import('src/Dashboard'))

// Assets
// Assets > ATS
const AtsOverview = lazy(() => import('src/Assets/AtsOverview'))
const AtsDashboard = lazy(() => import('src/Assets/AtsDashboard'))

// Assets > Cameras
// const CameraOverview = lazy(() => import('src/Assets/CameraOverview'))

// Assets > Generator
const GensetOverview = lazy(() => import('src/Assets/GensetOverview'))
const GensetDashboard = lazy(() => import('src/Assets/GensetOverview/Dashboard'))

// Assets > Generic
const GenericOverview = lazy(() => import('src/Assets/GenericOverview'))
const GenericDashboard = lazy(() => import('src/Assets/GenericOverview/Dashboard'))

// Assets > PDD
const PddOverview = lazy(() => import('src/Assets/PddOverview'))
const PddDashboard = lazy(() => import('src/Assets/PddDashboard'))
const PddLocation = lazy(() => import('src/Assets/PddOverview/Location'))

// Assets > Power meter
const PowerMeterOverview = lazy(() => import('src/Assets/PowerMeterOverview'))
const PowerMeterDashboard = lazy(() => import('src/Assets/PowerMeterDashboard'))

// Assets > Tanks
const TankOverview = lazy(() => import('src/Assets/TankOverview'))
const TankDashboard = lazy(() => import('src/Assets/TankOverview/Dashboard'))
const TankTransactions = lazy(() => import('src/Assets/TankOverview/Transactions'))

// Assets > UPS
const UpsOverview = lazy(() => import('src/Assets/UpsOverview'))
const UpsDashboard = lazy(() => import('src/Assets/UpsDashboard'))

// Assets > Vehicles
const VehicleOverview = lazy(() => import('src/Assets/VehicleOverview'))
const VehicleDashboard = lazy(() => import('src/Assets/VehicleOverview/Dashboard'))
const VehicleTransactions = lazy(() => import('src/Assets/VehicleOverview/Transactions'))

// Sites
const Sites = lazy(() => import('src/Sites'))
const SiteDetails = lazy(() => import('src/Sites/Details'))

// Reports
const ReportsViewer = lazy(() => import('src/Reports/Viewer'))
const ScheduledReports = lazy(() => import('src/Reports/Scheduled'))
const EditScheduledReports = lazy(() => import('src/Reports/Scheduled/Edit'))
const GeneratedReports = lazy(() => import('src/Reports/Generated'))
const GeneratedReportDetails = lazy(() => import('src/Reports/Generated/Details'))

// Alarm history
const AlarmHistory = lazy(() => import('src/AlarmHistory'))
const IncidentReport = lazy(() => import('src/AlarmHistory/Incident'))

// Tasks
const Tasks = lazy(() => import('src/Tasks'))
const EditTask = lazy(() => import('src/Tasks/Edit'))
const TaskDetails = lazy(() => import('src/Tasks/Details'))

// Settings
// Settings > Agents
const Agents = lazy(() => import('src/Settings/Agents'))
const ConfigureAgent = lazy(() => import('src/Settings/Agents/Configure'))
const ConfigureSensors = lazy(() => import('src/Settings/Agents/Sensors'))
const ConfigureAlarms = lazy(() => import('src/Settings/Agents/Alarms'))

// Settings > Agent groups
const AgentGroups = lazy(() => import('src/Settings/AgentGroups'))
const EditAgentGroup = lazy(() => import('src/Settings/AgentGroups/Edit'))

// Settings > Alarm actions
const AlarmActions = lazy(() => import('src/Settings/AlarmActions'))
const EditAlarmAction = lazy(() => import('src/Settings/AlarmActions/Edit'))

// Settings > Geozones
const GeoZones = lazy(() => import('src/Settings/GeoZones'))

// Settings > Organisation
const OrgSettings = lazy(() => import('src/Settings/Organisation'))

// Settings > Sites
const AdminSites = lazy(() => import('src/Settings/Sites'))
const EditSite = lazy(() => import('src/Settings/Sites/Edit'))
const SiteDetail = lazy(() => import('src/Admin/SiteDetail'))

// Settings > Users
const Users = lazy(() => import('src/Settings/Users'))
const EditUser = lazy(() => import('src/Settings/Users/Edit'))

// Settings > User groups
const UserGroups = lazy(() => import('src/Settings/UserGroups'))
const EditUserGroup = lazy(() => import('src/Settings/UserGroups/Edit'))

// Admin
// Admin > Agent logs
const AgentLogs = lazy(() => import('src/Admin/AgentLogs'))

// Admin > Agent templates
const AgentTemplates = lazy(() => import('src/Admin/AgentTemplates'))

// Admin > Alarm webhooks
const AlarmWebhooks = lazy(() => import('src/Admin/AlarmWebhooks'))
const EditAlarmWebhook = lazy(() => import('src/Admin/AlarmWebhooks/Edit'))

// Admin > Camera events
const CameraEvents = lazy(() => import('src/Admin/CameraEvents'))
const CameraEvent = lazy(() => import('src/Admin/CameraEvents/Event'))

// Admin > Event logs
const EventLogs = lazy(() => import('src/Admin/EventLogs'))

// Admin > Inmarsat logs
const InmarsatLogs = lazy(() => import('src/Admin/InmarsatLogs'))

// Admin > Modbus devices
const Modbus = lazy(() => import('src/Admin/Modbus'))
const EditModbus = lazy(() => import('src/Admin/Modbus/Edit'))
const EditRegistersModbus = lazy(() => import('src/Admin/Modbus/Registers'))

// Admin > Organisations
const Orgs = lazy(() => import('src/Admin/Orgs'))
const EditOrg = lazy(() => import('src/Admin/Orgs/Edit'))

// Admin > Roles
const Roles = lazy(() => import('src/Admin/Roles'))
const EditRole = lazy(() => import('src/Admin/Roles/Edit'))

// Admin > Scale factor
const ScaleFactor = lazy(() => import('src/Admin/ScaleFactor'))
const EditScaleFactor = lazy(() => import('src/Admin/ScaleFactor/Edit'))

// Admin > Sensor
const Sensors = lazy(() => import('src/Admin/Sensors'))
const EditSensor = lazy(() => import('src/Admin/Sensors/Edit'))

// Admin > Theme
const Theme = lazy(() => import('src/Admin/Theme'))

// Admin > Field gateway config
const FieldGatewayConfig = lazy(() => import('src/Admin/FieldGatewayConfig'))

const NotFound = lazy(() => import('./NotFound'))

interface Props {
  expandBtn?: ReactElement
  fullView?: boolean
}

export default function Router ({ expandBtn, fullView }: Props) {
  return (
    <Routes>
      <Route
        path="/user-profile"
        element={(
          <Page title="User Profile">
            <Container>
              <UserProfile />
            </Container>
          </Page>
        )}
      />

      {/* Main pages */}
      <Route
        path="/"
        element={(
          <Page title="Map">
            <GeoZoneMap expandBtn={expandBtn} fullView={fullView} />
          </Page>
        )}
      />

      <Route
        path="/my-dashboard"
        element={(
          <Page title="Dashboard">
            <Container>
              <Dashboard />
            </Container>
          </Page>
        )}
      />

      {/* Assets */}
      {/* Assets > ATS */}
      <Route
        path="/assets/ats"
        element={(
          <Page title="ATS">
            <Container>
              <AtsOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/ats/:assetId"
        element={(
          <Page title="ATS Details">
            <Container
              breadcrumbs={[
                { title: 'ATS', href: '/assets/ats' },
                { title: 'ATS Details' }
              ]}
            >
              <AtsDashboard />
            </Container>
          </Page>
        )}
      />

      {/* Assets > Cameras */}
      {/* <Route
        path="/assets/cameras"
        element={(
          <Page title="Cameras">
            <Container>
              <CameraOverview />
            </Container>
          </Page>
        )}
      /> */}

      {/* Assets > Generator */}
      <Route
        path="/assets/genset"
        element={(
          <Page title="Generators">
            <Container>
              <GensetOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/genset/:id"
        element={(
          <Page title="Generator Dashboard">
            <Container noVerticalPadding>
              <GensetDashboard />
            </Container>
          </Page>
        )}
      />

      {/* Assets > Generic */}
      <Route
        path="/assets/generic"
        element={(
          <Page title="Generic">
            <Container>
              <GenericOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/generic/:id"
        element={(
          <Page title="Generic Asset Dashboard">
            <Container noVerticalPadding>
              <GenericDashboard />
            </Container>
          </Page>
        )}
      />

      {/* Assets > PDD */}
      <Route
        path="/assets/duress"
        element={(
          <Page title="Personal Duress Devices">
            <Container>
              <PddOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/duress/:assetId"
        element={(
          <Page title="PDD Details">
            <Container
              breadcrumbs={[
                { title: 'Personal Duress Devices', href: '/assets/duress' },
                { title: 'PDD Details' }
              ]}
            >
              <PddDashboard />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/duress/:assetId/map"
        element={(
          <Page title="PDD Location">
            <PddLocation expandBtn={expandBtn} />
          </Page>
        )}
      />

      {/* Assets > Power meter */}
      <Route
        path="/assets/power-meter"
        element={(
          <Page title="Power Meter">
            <Container>
              <PowerMeterOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/power-meter/:assetId"
        element={(
          <Page title="Power Meter Details">
            <Container
              breadcrumbs={[
                { title: 'Power Meter', href: '/assets/power-meter' },
                { title: 'Power Meter Details' }
              ]}
            >
              <PowerMeterDashboard />
            </Container>
          </Page>
        )}
      />

      {/* Assets > Tanks */}
      <Route
        path="/assets/tank"
        element={(
          <Page title="Tanks">
            <Container>
              <TankOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/tank/:assetId"
        element={(
          <Page title="Tank Dashboard">
            <Container noVerticalPadding>
              <TankDashboard />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/tank/:assetId/transactions"
        element={(
          <Page title="Tank Transactions">
            <Container>
              <TankTransactions />
            </Container>
          </Page>
        )}
      />

      {/* Assets > UPS */}
      <Route
        path="/assets/ups"
        element={(
          <Page title="UPS">
            <Container>
              <UpsOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/ups/:assetId"
        element={(
          <Page title="UPS Details">
            <Container
              breadcrumbs={[
                { title: 'UPS', href: '/assets/ups' },
                { title: 'UPS Details' }]
              }
            >
              <UpsDashboard />
            </Container>
          </Page>
        )}
      />

      {/* Assets > Vehicles */}
      <Route
        path="/assets/vehicle"
        element={(
          <Page title="Vehicles">
            <Container>
              <VehicleOverview />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/vehicle/:id"
        element={(
          <Page title="Vehicle Dashboard">
            <Container noVerticalPadding>
              <VehicleDashboard />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/assets/vehicle/:assetId/transactions"
        element={(
          <Page title="Vehicle Transactions">
            <Container>
              <VehicleTransactions />
            </Container>
          </Page>
        )}
      />

      {/* Sites */}
      <Route
        path="/sites"
        element={(
          <Page title="Sites">
            <Container>
              <Sites />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/site/:siteId"
        element={(
          <Page title="Site Detail" role={['ROLE_SUPERADMIN']}>
            <Container
              breadcrumbs={[
                { title: 'Sites', href: '/Sites' },
                { title: 'Site Details' }
              ]}
            >
              <SiteDetails />
            </Container>
          </Page>
        )}
      />

      {/* Reports */}
      {/* Reports > Viewer */}
      <Route
        path="/reports/viewer"
        element={(
          <Page title="Reports Viewer">
            <Container noVerticalPadding>
              <ReportsViewer />
            </Container>
          </Page>
        )}
      />

      {/* Reports > Scheduled */}
      <Route
        path="/reports/scheduled"
        element={(
          <Page title="Scheduled Reports" role={['ROLE_SUPERADMIN', 'ROLE_ADMIN']}>
            <Container>
              <ScheduledReports />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/reports/scheduled/edit/:reportScheduleId"
        element={(
          <Page title="Edit Scheduled Report">
            <Container
              breadcrumbs={[
                { title: 'Scheduled Reports', href: '/reports/scheduled' },
                { title: 'Edit Report' }
              ]}
            >
              <EditScheduledReports />
            </Container>
          </Page>
        )}
      />

      {/* Reports > Generated */}
      <Route
        path="/reports/generated"
        element={(
          <Page title="Generated Reports">
            <Container noVerticalPadding>
              <GeneratedReports />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/reports/generated/:generatedReportId"
        element={(
          <Page title="Report">
            <Container noVerticalPadding>
              <GeneratedReportDetails />
            </Container>
          </Page>
        )}
      />

      {/* Alarm history */}
      <Route
        path="/alarms"
        element={(
          <Page title="Alarm History">
            <Container>
              <AlarmHistory state={alarmHistoryState} />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/alarms/incident/:incidentId"
        element={(
          <Page title="Incident">
            <Container noVerticalPadding>
              <IncidentReport />
            </Container>
          </Page>
        )}
      />

      {/* Tasks */}
      <Route
        path="/tasks"
        element={(
          <Page title="Tasks">
            <Container>
              <Tasks />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/tasks/edit/:taskId"
        element={(
          <Page title="Edit Task">
            <Container
              breadcrumbs={[
                { title: 'Tasks', href: '/tasks' },
                { title: 'Edit Task' }
              ]}
              form
            >
              <EditTask />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/tasks/details/:taskId"
        element={(
          <Page title="Details" role={adminRoles}>
            <Container
              breadcrumbs={[
                { title: 'Tasks', href: '/tasks' },
                { title: 'Details' }
              ]}
            >
              <TaskDetails />
            </Container>
          </Page>
        )}
      />

      {/* Settings */}
      {/* Settings > Agent config */}
      <Route
        path="/settings/agents"
        element={(
          <Page title="Agent Config" role={adminRoles}>
            <Container>
              <Agents />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/agents/:agentId"
        element={(
          <Page role={adminRoles}>
            <Container
              breadcrumbs={[
                { title: 'Agent config', href: '/settings/agents' },
                { title: 'Configure' }
              ]}
            >
              <ConfigureAgent />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/agents/:agentId/sensors"
        element={(
          <Page role={adminRoles}>
            <Container
              breadcrumbs={[
                { title: 'Agent config', href: '/settings/agents' },
                { title: 'Edit Sensors' }
              ]}
            >
              <ConfigureSensors />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/agents/:agentId/alarms"
        element={(
          <Page role={adminRoles}>
            <Container
              breadcrumbs={[
                { title: 'Agent config', href: '/settings/agents' },
                { title: 'Edit Alarms' }
              ]}
            >
              <ConfigureAlarms />
            </Container>
          </Page>
        )}
      />

      {/* Settings > Agent groups */}
      <Route
        path="/settings/agent-groups"
        element={(
          <Page title="Agent Groups" role={adminRoles}>
            <Container>
              <AgentGroups />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/agent-groups/edit/:agentGroupId"
        element={(
          <Page title="Edit Agent Group" role={adminRoles}>
            <Container
              breadcrumbs={[
                { title: 'Agent Groups', href: '/settings/agent-groups' },
                { title: 'Edit Agent Group' }
              ]}
              form
            >
              <EditAgentGroup />
            </Container>
          </Page>
        )}
      />

      {/* Settings > Alarm actions */}
      <Route
        path="/settings/alarm-actions"
        element={(
          <Page title="Alarm Actions" role={adminRoles}>
            <Container>
              <AlarmActions />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/alarm-actions/edit/:alarmActionId"
        element={(
          <Page title="Edit Alarm Action" role={adminRoles}>
            <Container
              breadcrumbs={[
                { title: 'Alarm actions', href: '/settings/alarm-actions' },
                { title: 'Edit Alarm Action' }
              ]}
              form
            >
              <EditAlarmAction />
            </Container>
          </Page>
        )}
      />
      {/* Settings > Geozones */}
      <Route
        path="/settings/geozones"
        element={(
          <Page title="Geozones" role={adminRoles}>
            <Container>
              <GeoZones />
            </Container>
          </Page>
        )}
      />

      {/* Settings > Organisation */}
      <Route
        path="/settings/org"
        element={(
          <Page title="Org Settings" role={adminRoles}>
            <Container>
              <OrgSettings />
            </Container>
          </Page>
        )}
      />

      {/* Settings > Sites */}
      <Route
        path="/settings/sites"
        element={(
          <Page title="Sites" role={adminRoles}>
            <Container>
              <AdminSites />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/sites/edit/:siteId"
        element={(
          <Page title="Edit Site" role={adminRoles}>
            <Container
              breadcrumbs={[
                { title: 'Sites', href: '/settings/sites' },
                { title: 'Edit Site' }
              ]}
              form
            >
              <EditSite />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/sites/flow/:siteId"
        element={(
          <Page title="Site Detail" role={adminRoles}>
            <Container
              noHorizontalPadding
              noVerticalPadding
              occupyMaxHeight
            >
              <SiteDetail />
            </Container>
          </Page>
        )}
      />

      {/* Settings > Users */}
      <Route
        path="/settings/users"
        element={(
          <Page title="Users" role={adminRoles}>
            <Container>
              <Users />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/users/edit/:userId"
        element={(
          <Page title="Edit User" role={adminRoles}>
            <Container
              breadcrumbs={[
                { title: 'Users', href: '/settings/users' },
                { title: 'Edit User' }
              ]}
              form
            >
              <EditUser />
            </Container>
          </Page>
        )}
      />

      {/* Settings > User groups */}
      <Route
        path="/settings/user-groups"
        element={(
          <Page title="User Groups" role={adminRoles}>
            <Container>
              <UserGroups />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/settings/user-groups/edit/:userGroupId"
        element={(
          <Page title="Edit User Group" role={adminRoles}>
            <Container
              breadcrumbs={[
                { title: 'User Groups', href: '/settings/user-groups' },
                { title: 'Edit User Group' }
              ]}
              form
            >
              <EditUserGroup />
            </Container>
          </Page>
        )}
      />

      {/* Admin */}
      {/* Admin > Agent logs */}
      <Route
        path="/my-admin/agent/logs"
        element={(
          <Page title="Agent Logs" role="ROLE_SUPERADMIN">
            <Container>
              <AgentLogs state={agentLogsState} />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Agent templates */}
      <Route
        path="/my-admin/agent-templates"
        element={(
          <Page title="Agent Templates" role="ROLE_SUPERADMIN">
            <Container>
              <AgentTemplates />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Alarm webhooks */}
      <Route
        path="/my-admin/alarm-webhooks"
        element={(
          <Page title="Alarm Webhooks" role="ROLE_SUPERADMIN">
            <Container>
              <AlarmWebhooks />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/alarm-webhooks/edit/:alarmWebhookId"
        element={(
          <Page title="Edit Alarm Webhook" role="ROLE_SUPERADMIN">
            <Container
              form
              breadcrumbs={[
                { title: 'Alarm Webhooks', href: '/my-admin/alarm-webhooks' },
                { title: 'Edit Alarm Webhook' }
              ]}
            >
              <EditAlarmWebhook />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Camera events */}
      <Route
        path="/my-admin/camera-events"
        element={(
          <Page title="Camera Events" role="ROLE_SUPERADMIN">
            <Container>
              <CameraEvents />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/camera-events/:cameraId"
        element={(
          <Page title="Camera Events" role="ROLE_SUPERADMIN">
            <Container>
              <CameraEvent />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Event logs */}
      <Route
        path="/my-admin/event-logs"
        element={(
          <Page title="Event Logs" role="ROLE_SUPERADMIN">
            <Container>
              <EventLogs />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Inmarsat logs */}
      <Route
        path="/my-admin/inmarsat-logs"
        element={(
          <Page title="Inmarsat Logs" role="ROLE_SUPERADMIN">
            <Container>
              <InmarsatLogs />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Modbus devices */}
      <Route
        path="/my-admin/modbus-devices"
        element={(
          <Page title="Modbus Devices" role="ROLE_SUPERADMIN">
            <Container>
              <Modbus />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/modbus-devices/edit/:deviceId"
        element={(
          <Page title="Edit Modbus Device" role="ROLE_SUPERADMIN">
            <Container
              form
              breadcrumbs={[
                { title: 'Modbus Devices', href: '/my-admin/modbus-devices' },
                { title: 'Edit Modbus Device' }
              ]}
            >
              <EditModbus />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/modbus-devices/edit-registers/:deviceId"
        element={(
          <Page title="Edit Registers" role="ROLE_SUPERADMIN">
            <Container
              breadcrumbs={[
                {
                  title: 'Modbus Device Register',
                  href: '/my-admin/modbus-devices'
                },
                { title: 'Edit Registers' }
              ]}
              form
            >
              <EditRegistersModbus />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Organisations */}
      <Route
        path="/my-admin/organisations"
        element={(
          <Page title="Organisations" role="ROLE_SUPERADMIN">
            <Container>
              <Orgs />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/organisations/edit/:orgId"
        element={(
          <Page title="Edit Organisation" role="ROLE_SUPERADMIN">
            <Container
              breadcrumbs={[
                { title: 'Organisations', href: '/my-admin/organisations' },
                { title: 'Edit Organisation' }
              ]}
              form
            >
              <EditOrg />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Roles */}
      <Route
        path="/my-admin/roles"
        element={(
          <Page title="Roles" role="ROLE_SUPERADMIN">
            <Container>
              <Roles />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/roles/edit/:roleId"
        element={(
          <Page title="Edit Role" role="ROLE_SUPERADMIN">
            <Container
              breadcrumbs={[
                { title: 'Roles', href: '/my-admin/roles' },
                { title: 'Edit Role' }
              ]}
              form
            >
              <EditRole />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Scale factor */}
      <Route
        path="/my-admin/scale-factor"
        element={(
          <Page title="Scale Factor" role="ROLE_SUPERADMIN">
            <Container>
              <ScaleFactor />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/scale-factor/edit/:id"
        element={(
          <Page title="Edit Scale Factor" role="ROLE_SUPERADMIN">
            <Container
              breadcrumbs={[
                {
                  title: 'Scale Factor',
                  href: '/my-admin/scale-factor'
                },
                {
                  title: 'Edit Scale Factor'
                }
              ]}
              form
            >
              <EditScaleFactor />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Sensors */}
      <Route
        path="/my-admin/sensors"
        element={(
          <Page title="Sensors" role="ROLE_SUPERADMIN">
            <Container>
              <Sensors />
            </Container>
          </Page>
        )}
      />
      <Route
        path="/my-admin/sensors/edit/:sensorId"
        element={(
          <Page title="Edit Sensor" role="ROLE_SUPERADMIN">
            <Container
              breadcrumbs={[
                { title: 'Sensors', href: '/my-admin/sensors' },
                { title: 'Edit Sensor' }
              ]}
              form
            >
              <EditSensor />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Theme */}
      <Route
        path="/my-admin/theme"
        element={(
          <Page title="Manage Theme" role="ROLE_SUPERADMIN">
            <Container>
              <Theme />
            </Container>
          </Page>
        )}
      />

      {/* Admin > Field gateway config */}
      <Route
        path="/my-admin/field-gateway-config"
        element={(
          <Page title="Field Gateway Config" role="ROLE_SUPERADMIN">
            <Container>
              <FieldGatewayConfig />
            </Container>
          </Page>
        )}
      />

      {/* 404 Route. Do not place any after this! */}
      <Route
        path="*"
        element={(
          <Page title="Page Not Found">
            <NotFound />
          </Page>
        )}
      />
    </Routes>
  )
}
