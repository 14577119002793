/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { type User, sendStillAlive } from '@venturi-io/api/src/userManager/auth'
import { getUserContext } from 'src/UserContext'
import LoadingOverlay from 'src/Layout/LoadingOverlay'
import Router from './Router'
import LazyComponent from './LazyComponent'
import { useZendesk } from './ZendeskContext'
import type { Org } from './App'

const AppFrame = lazy(() => import('./Layout/AppFrame'))
const UserTour = lazy(() => import('./UserTour'))

export const publicLocations = ['join', 'accountVerification', 'resetPassword']

interface Props {
  user: User
  org: Org
  signout: () => void
}

export default function Core ({ user, org, signout }: Props) {
  const location = useLocation()
  const UserContext = getUserContext(user, signout)
  const [int, setInt] = useState<NodeJS.Timeout | null>(null)
  const controller = new AbortController()
  const { hide, setCookies } = useZendesk()

  useEffect(() => {
    hide()
    setCookies(true)
  }, [])

  useEffect(() => {
    // This will automatically sign out the current user if the page URL is public
    if (user !== null && publicLocations.includes(location.pathname.split('/')[1])) {
      signout()
    }
  }, [user, location])

  const sendAlive = () => {
    void sendStillAlive({}, user['X-Auth-Token'], '', controller.signal)
  }

  const handleOnActive = () => {
    sendAlive()

    if (!int) {
      setInt(
        setInterval(sendAlive, 1000 * 60 * 5)
      )
    }
  }

  const handleOnIdle = () => {
    if (int) {
      clearInterval(int)
      setInt(null)
    }
  }

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 1000
  })

  useEffect(() => {
    handleOnActive()

    return () => {
      handleOnIdle()
      controller.abort()
    }
  })

  return (
    <UserContext.Provider value={{ ...user, signout }}>
      <AppFrame signout={signout} orgId={org.orgId}>
        <LazyComponent component={<Router />} loader={<LoadingOverlay visible />} />
      </AppFrame>
      {user.onboardingStatus === 'NOT_STARTED' && (
        <LazyComponent component={<UserTour />} loader={null} />
      )}
    </UserContext.Provider>
  )
}
