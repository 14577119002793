import { type SuspenseProps, type ReactNode, Suspense } from 'react'

interface Props {
  component: ReactNode
  loader?: SuspenseProps['fallback']
}

export default function ({ component, loader }: Props) {
  return (
    <Suspense fallback={loader}>
      {component}
    </Suspense>
  )
}
